<template>
  <div v-if="selectedUbicacion">
    <b-overlay
      :show="spinner"
      spinner-variant="primary"
    >
      <b-card>
        <ubicacionesForm
          ubicacionSubmit="Editar Ubicación"
          :ubicacion="selectedUbicacion"
          @processUbicacion="editarUbicacion"
        />
      </b-card>
    </b-overlay>
  </div>
</template>

<script>

import { BCard, BOverlay } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const ubicacionesForm = () => import('@/layouts/components/Ubicaciones/UbicacionesForm.vue')

export default {
  components: {
    BCard,
    BOverlay,
    ubicacionesForm,
  },
  data() {
    return {
      spinner: false,
    }
  },
  computed: {
    ...mapState('ubicaciones', ['selectedUbicacion']),
  },
  methods: {
    ...mapActions({ updateUbicacion: 'ubicaciones/updateUbicaciones' }),
    editarUbicacion(ubicacion) {
      this.spinner = true
      this.updateUbicacion(ubicacion).then(() => {
        const errorUbicaciones = store.state.ubicaciones
        const errorMessage = errorUbicaciones.errorMessage.errors
        if (!errorUbicaciones.error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ubicación editada con éxito 👍',
              text: `La ubicación "${ubicacion.nombre}" fue editada con éxito!`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          },
          {
            timeout: 4000,
          })
          this.$router.replace({
            name: 'ubicaciones',
          })
        } else if (errorMessage.nombre) {
          this.$swal({
            title: 'Error!',
            text: `${errorMessage.nombre[0]}!`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else if (errorUbicaciones.error) {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulento!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        this.spinner = false
      })
    },
  },
}
</script>
